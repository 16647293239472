import React from "react";

const oneStar = (
  <div className="flex items-center mb-1">
    <svg
      aria-hidden="true"
      className="w-5 h-5 text-yellow-400"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>First star</title>
      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
    </svg>
    <svg
      aria-hidden="true"
      className="w-5 h-5 text-gray-400"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Second star</title>
      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
    </svg>
    <svg
      aria-hidden="true"
      className="w-5 h-5 text-gray-400"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Third star</title>
      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
    </svg>
    <svg
      aria-hidden="true"
      className="w-5 h-5 text-gray-400"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Fourth star</title>
      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
    </svg>
    <svg
      aria-hidden="true"
      className="w-5 h-5 text-gray-400"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Fifth star</title>
      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
    </svg>
    <h3 className="ml-2 text-sm font-semibold text-gray-900 dark:text-dark">
      Thinking to buy another one!
    </h3>
  </div>
);

const twoStar = (
  <div className="flex items-center mb-1">
    <svg
      aria-hidden="true"
      className="w-5 h-5 text-yellow-400"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>First star</title>
      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
    </svg>
    <svg
      aria-hidden="true"
      className="w-5 h-5 text-yellow-400"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Second star</title>
      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
    </svg>
    <svg
      aria-hidden="true"
      className="w-5 h-5 text-gray-400"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Third star</title>
      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
    </svg>
    <svg
      aria-hidden="true"
      className="w-5 h-5 text-gray-400"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Fourth star</title>
      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
    </svg>
    <svg
      aria-hidden="true"
      className="w-5 h-5 text-gray-400"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Fifth star</title>
      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
    </svg>
    <h3 className="ml-2 text-sm font-semibold text-gray-900 dark:text-white">
      Thinking to buy another one!
    </h3>
  </div>
);

const threeStar = (
  <div className="flex items-center mb-1">
    <svg
      aria-hidden="true"
      className="w-5 h-5 text-yellow-400"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>First star</title>
      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
    </svg>
    <svg
      aria-hidden="true"
      className="w-5 h-5 text-yellow-400"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Second star</title>
      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
    </svg>
    <svg
      aria-hidden="true"
      className="w-5 h-5 text-yellow-400"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Third star</title>
      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
    </svg>
    <svg
      aria-hidden="true"
      className="w-5 h-5 text-gray-400"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Fourth star</title>
      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
    </svg>
    <svg
      aria-hidden="true"
      className="w-5 h-5 text-gray-400"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Fifth star</title>
      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
    </svg>
    <h3 className="ml-2 text-sm font-semibold text-gray-900 dark:text-white">
      Thinking to buy another one!
    </h3>
  </div>
);

const fourStar = (
  <div className="flex items-center mb-1">
    <svg
      aria-hidden="true"
      className="w-5 h-5 text-yellow-400"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>First star</title>
      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
    </svg>
    <svg
      aria-hidden="true"
      className="w-5 h-5 text-yellow-400"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Second star</title>
      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
    </svg>
    <svg
      aria-hidden="true"
      className="w-5 h-5 text-yellow-400"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Third star</title>
      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
    </svg>
    <svg
      aria-hidden="true"
      className="w-5 h-5 text-yellow-400"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Fourth star</title>
      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
    </svg>
    <svg
      aria-hidden="true"
      className="w-5 h-5 text-gray-400"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Fifth star</title>
      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
    </svg>
    <h3 className="ml-2 text-sm font-semibold text-gray-900 dark:text-white">
      Thinking to buy another one!
    </h3>
  </div>
);

const fiveStar = (
  <div className="flex flex-wrap items-center mb-1">
    <svg
      aria-hidden="true"
      className="w-5 h-5 text-yellow-400"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>First star</title>
      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
    </svg>
    <svg
      aria-hidden="true"
      className="w-5 h-5 text-yellow-400"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Second star</title>
      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
    </svg>
    <svg
      aria-hidden="true"
      className="w-5 h-5 text-yellow-400"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Third star</title>
      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
    </svg>
    <svg
      aria-hidden="true"
      className="w-5 h-5 text-yellow-400"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Fourth star</title>
      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
    </svg>
    <svg
      aria-hidden="true"
      className="w-5 h-5 text-yellow-400"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Fifth star</title>
      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
    </svg>
   
  </div>
);

export default {
  book: [
    {
      title: "Lam-Ang",
      reviews: [
        {
          name: "EM- Goodreads",
          date: "August 26, 2024",
          rating: fiveStar,
          body: "This book should be a staple in all schools! It’s sad that we know more about western mythologies but not much of our own. I even know people who doesn’t even know who Lam-ang is 😨! This book was a fast read and easy to read through. It was so entertaining that I was unable to put it down. There are a lot of elements where growing up, we’ve heard of the stories. I’m not sure if PH myths and legends are still known by the newer generations. It was easy to imagine the scenarios even if this was set during the pre-colinial period.There was no dull moment while reading the book. The terrifying elementals, the different elementals from serenas, the giant crocodiles, and a whole lot more were incorporated and excecuted beautifully in this story which made Lam-ang’s journey one heck of an adventure. I cannot stress how strongly I believe in this book, I think the entire series should be part of the school curriculum to keep our mythologies alive. I got my copy as an ebook but also might get a physical copy to hand it down (or maybe I should get this as christmas gifts to my godkids 🤔)",
        },
        {
          name: "Read Love Irie - Goodreads",
          date: "August 18, 2024",
          rating: fiveStar,
          body: "There is so much to love about this story. I love learning Ilocano words. I love Gibuan (who stood as a father figure to Lam-Ang), and Mangmankik (who is Lam-Ang's spirit guide). I love the many mythical creatures present. I love the good and the gory details. I especially love Lam-Ang's growth. I teared up a couple of times as I got carried away with some scenes. I greatly enjoyed my journey with Lam-Ang! I am in awe with how much invested I am in this book. This has been engraved in my heart and couldn't be any more proud to share how epic this story is on Philippine mythology and folklore. This is learning while having fun. The details will pique your interest and fuel your thirst for more adventure. I am looking forward to reading the next books in this series!",
        },
        {
          name: "EGO - Goodreads",
          date: "August 9, 2024",
          rating: fiveStar,
          body: "This is a captivating read, perfect for both youngsters and adults who want to dive into the rich tapestry of Filipino folklore. The narrative is masterfully crafted, making you feel as though you are right beside Lam-ang, battling alongside him. The vivid descriptions bring the story to life, allowing readers to immerse themselves in the legendary world of this epic hero. Not only does this book offer an engaging tale, but it also provides deep insights into Filipino culture. It goes beyond storytelling, offering lessons about heritage and traditions that are both informative and enriching. It's a book that entertains while educating, making it a highly recommended read for anyone interested in Filipino myths or looking to explore the cultural roots of the Philippines.",
        },
        {
          name: "ininalcantara - Instagram",
          date: "July 18, 2024",
          rating: fiveStar,
          body:"I really enjoyed this book! Once I got the names and terms sorted, reading it was a breeze! All the adventures of Lam-Ang were action-packed... I was holding my breath when I read his last one. There is a lot of interesting information in this book, I wrote two pages worth of notes. This should be required reading. ♡ AGYAMANAK, sir Herbie De Leon @alamat_books for writing this beautiful book. I am a fan!"
        },
        {
          name: "Andy",
          date: "August 25, 2022",
          rating: fiveStar,
          body: "I'm still halfway into reading but let me tell you I THOROUGHLY enjoyed every second of it! It's such a fresh take on the fantasy genre. I was not well knowledgeable about Filipino mythology but this book was able to spice my interest in other cultures! There is intense world building by the author by including descriptive maps to help indulge yourself within the story. Definitely pick this up, it's worth your time!",
        },
        {
          name: "Robert Keith Rickells",
          date: "October 17, 2023",
          rating: fiveStar,
          body: "Anybody with an interest in the folk lore and tales from the Philippines will thoroughly enjoy this book and the other 3 in the Alamat series. Lam Ang is beautifully written and the story is a joy to follow. I am from the UK and live between the UK and the Philippines with my wife and I am familiar with some of the places in the book and others I was able to google and research and compare with the maps within the book. This for me also made the book so much more joyous as I did some of my own extra reading and research. I have just started reading the second book Lumalindaw and have the other two in the current run of the series waiting to be read (Taguwasi and Urduja). Even if you are not aware of the folk lore and tales of the Philippines, trust me you will enjoy these books and they will draw you into this fascinating world. Thankyou Herbert, I am looking forward to more of you're work.",
        },
        {
          name: "PJ Nadela",
          date: "December 15, 2023",
          rating: fiveStar,
          body: "Lam-Ang by Sir Herbert De Leon is an absolute triumph in storytelling, seamlessly marrying the classic elements of the hero's journey with the richness of Filipino epics. De Leon's narrative prowess takes center stage, breathing new life into the timeless tale of Lam-Ang, and, in doing so, makes a substantial contribution to the realm of Filipino epics. The book's intricate storytelling skillfully navigates the cosmic forces, societal struggles, and mythical dimensions inherent in these narratives. This, in turn, plays a vital role in not only preserving but revitalizing the cultural and literary heritage of the Philippines. 'Lam-Ang' stands as a beacon, showcasing the enduring power of storytelling in shaping and celebrating cultural identity.",
        },
        {name: "Bob of America",
          date: "April 26, 2024",
          rating: fiveStar,
          body:"My wife Annie Dukha O'Laskey and I met Mr. De Leon at the Sinulog 24 event in Van Nuys in January. I haven't read a Fantasy-type hero book since I was about 12. A little older now, I bought his book Lam-Ang more to support him than to actually read it, but I did read it, and I had difficulties putting it down. I had always had a challenge in reading as I only read words. That was it. No pictures in my head. No feelings. Just the words. I've always been envious of people who were able to read a book and feel like they were part of it. Mr. De Leon though, to my surprise, painted a picture of an adventure and a world that I found myself immersed in. My heart would beat like a drum. I'd have shortness of breath. I'd find that my muscles tense. And maybe, just maybe mind you, a tear came to my eye. For the first time that I can remember I lived within a book/story. I teach Filipino Martial Arts as part of my curriculum and I tell my students that martial arts is just that an art, it's not a law, but an art. As a painter uses paint and a sculptor uses clay. you must take what learn and make it yours. And that is what Mr. De Leon has done. He has taken words and made them his Art. I highly recommend his book for all Filipinos, Expats married to a Filipino/a, FilAms, OFW's or anyone interested in a Good Read..."}
      ],
    },
    {
      title: "Lumalindaw",
      reviews: [
        {name: "Bookmom23",
        date: "March 24, 2023",
        rating: fiveStar,
        body:"LOTR of the Philippines! Finally, a book on Filipino fantasy and history! Must read!"},
      ],
    },
    { title: "Taguwasi", 
      reviews: [
        {name: "Chay - Goodreads",
        date: "June 21, 2023",
        rating: fiveStar,
        body:"Got hooked on 1st book, went straight to 2nd book. Now halfway the 3rd book and I'm reading away. I'm sensing an 'Avengers' kind of thing here and loving it so far."}
    ] },
    {
      title: "Urduja",
      reviews: [
        {name: "Eli Jaden - Goodreads",
          date: "January 1, 2024",
          rating: fiveStar,
          body:"Spoiler Free: I haven't been this invested in a world beyond our own in a long time. I've read many mythologies and fantasy novels that have captured my interest but none quite like this. Though my opinion as a Philippine Mythology nerd gives me a lot of bias, this novel I believe is a grand introduction to the world of Philippine folklore and myth. I also confess I am reading this series out of order, only because Urduja is a figure I've been trying desperately to research for a long time now. Overall I really enjoyed reading this, I don't mean to make comparisons but the combination of this being something akin to a superhero origin along with a fantasy drama made my heart pound through most of the readthrough! In the most concise and affectionate words possible: holy shit. Lord of the Rings and Percy Jackson be damned, but I thoroughly enjoyed this. It makes me wish I had something similar growing up to read."}
      ],
    },
    {
      title: "Tala",
      reviews: [
        {name: "Robert Keith Rickells - UK",
          date: "February 7, 2024",
          rating: fiveStar,
          body: "Another fine book in the Alamat Series from Herbert. Wonderfully written and a joy to read. Having been in touch with Herbert personally, there are more books planned. I can't wait."
        },
        {name: "Eli Jaden - Goodreads",
          date: "January 10, 2024",
          rating: fourStar,
          body: "In semi-continuation from both the first book and Urduja, I loved reading about Tala! Out of the pantheon, she’s my favorite and I gotta say this one felt almost like a lost samurai story. So much rides against her from the moment she stepped into the mortal realm and from then on it was build-up then tremendous pain—I loved it. I can’t wait to see where the series goes next!"
        }
        ],
    },
    {
      title: "Lam-Ang Ascension",
      reviews: [
        {name: "Bob of America",
          date: "April 26, 2024",
          rating: fiveStar,
          body: "Wow..! I’ve got to say it was a long and eagerly anticipated book, and it was worth the wait. I read it in three days..! Mr. De Leon needs to write faster..! He he he… I love how Mr. De Leon while writing about one Lankandian’s story, the story of another Lankandian was or is still in progress. If that makes sense. In other words, the previous Hero or A Hero’s past wasn’t at a standstill or on pause. Things were and are happening even if Mr. De Leon isn’t focusing on that Hero at that time. Only to be shared at a later time. For me, providing layer upon layer to the Alamat series. Kimat, Saridandan, Anes, Sumarang ..! Talk about a Tug of War..! Despair, Surprise & Elation, Despair..! Did I say Diapair..?!? 🤪 I don’t know how well Mr. De Leon’s books sell. Again I’ll say it like I have in another review. I don’t know him and only met him once at a fair when I bought his first book Lam-Ang. And I was hooked. I don’t know if a lot of American/Expats buy them. However, I believe they should because it is jam-packed with Myths and Tales of their Wife’s/Husband’s land. Mr De Leone readily admits that he expands on them. Poons, Dians, Anitos, Kapre, mananngal, Nilalang-Lubong, Aswang, Mandarangkal, Mananangas, Dalaketnon, Berbakan, Enkanto, Mamaw, Tikbalang, Kaluwalhatian & Kasannan and more will be found in this and his other books. As a side note his books bring up topics of discussion with my wife and myself, as I enjoy sharing with her, she will giggle and laugh and tell me her childhood stories as they relate to the tales Mr. De Leon shares. Mr. De Leon has a lot to offer the Filipino Community in his books, if you have FilAm young adults, this series is pretty much PG, and a great way of exposing them to their Filipino heritage. Anyway as an Amerikano. I highly recommend this Series."
        }
      ],
    },
  ],
};
